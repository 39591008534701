import PropTypes from "prop-types";
import React from "react";
import LanguageChanger from "./sub-components/LanguageChanger";
import { useTranslation } from "react-i18next";

const HeaderTop = ({ borderStyle }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`header-top-wap ${
        borderStyle === "fluid-border" ? "border-bottom" : ""
      }`}
    >
      <LanguageChanger />
      <div className="header-offer">
        <p>{t("order_before_slogan")}</p>
      </div>
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
