import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls from "../../urls";
import { useSelector } from "react-redux";
import { formatCategoryName } from "../../helpers/formatters";

const NavMenu = () => {
  const { t, i18n } = useTranslation();
  const categories = useSelector(
    (state) => state.firestoreReducer.ordered.categories
  );
  const [activeSubCategory, setActiveSubCategory] = useState(null);

  const handleSetActiveSubCategory = (id) => {
    setActiveSubCategory(id);
  };

  return (
    <div className={"main-menu"}>
      <nav>
        <ul style={{ textWrap: "nowrap" }}>
          <li>
            <NavLink to={urls.home} activeClassName="active" exact>
              {t("home")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={urls.shop}
              className={"has-submenu arrow-down"}
              activeClassName="active"
              exact
            >
              {t("products")}
            </NavLink>
            <ul
              className={"submenu"}
              onMouseLeave={() => setActiveSubCategory(null)}
            >
              <li onMouseEnter={() => setActiveSubCategory(null)}>
                <NavLink to={urls.shop} exact>
                  {t("all_products")}
                </NavLink>
              </li>
              {!!categories &&
                categories.map((category) => (
                  <>
                    <li
                      key={category.id}
                      onMouseEnter={() =>
                        handleSetActiveSubCategory(category.id)
                      }
                    >
                      <NavLink
                        to={urls.categorySlug + category.slug}
                        exact
                        className={
                          category.subcategories.length > 0
                            ? "has-submenu arrow-right"
                            : ""
                        }
                      >
                        {formatCategoryName(category, i18n.language)}
                      </NavLink>
                      {category.id === activeSubCategory &&
                        category.subcategories.length > 0 && (
                          <ul className={"subcategories"}>
                            {category.subcategories.map((item) => (
                              <li key={item.subcategoryId}>
                                <NavLink
                                  to={
                                    urls.categorySlug +
                                    category.slug +
                                    "/" +
                                    item.slug
                                  }
                                  exact
                                >
                                  {formatCategoryName(item, i18n.language)}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                    </li>
                  </>
                ))}
            </ul>
          </li>
          <li>
            <NavLink to={urls.winkel} activeClassName="active" exact>
              {t("store")}
            </NavLink>
          </li>
          <li>
            <NavLink to={urls.recipes} activeClassName="active" exact>
              {t("recipes")}
            </NavLink>
          </li>
          <li>
            <NavLink to={urls.about} activeClassName="active">
              {t("about_us")}
            </NavLink>
          </li>
          {/*<li>*/}
          {/*  <NavLink to={urls.delivery_returns} activeClassName="active">*/}
          {/*    {t("shipping")}*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          <li>
            <NavLink to={urls.contact} activeClassName="active">
              {t("contact")}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavMenu;
